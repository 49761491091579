import React from "react"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

export default props => (
  <CookieConsent
    acceptOnScroll={false}
    acceptOnScrollPercentage={15}
    buttonText="Ich habe verstanden"
    style={{ backgroundColor: "#dimgrey", fontSize: "0.8em" }}
  >
    Diese Seite verwendet Cookies: Durch die Nutzung unserer Services erklären
    Sie sich damit einverstanden, dass wir Cookies setzen.{" "}
    <Link className="red-link" to="/datenschutz">
      Weitere Informationen zum Thema.
    </Link>
  </CookieConsent>
)
