import React from "react"
import Navbar from "./navbar"
import MetaData from "./metaData"
import { VerticleButton as ScrollUpButton } from "react-scroll-up-button"
import CookieBox from "./boxCookies"

import { FaPhoneSquare } from "react-icons/fa"

export default () => (
  <div className="sticky-top">
    <MetaData />
    <ScrollUpButton />
    <div className="telephone-top">
      <FaPhoneSquare
        className="mirror"
        style={{
          position: "relative",
          top: "-1px",
        }}
      />{" "}
      02133 / 63294
    </div>
    <Navbar />
    <div style={{ backgroundColor: "lightgray", paddingTop: "1px" }} />
    <CookieBox />
  </div>
)
